/**
 * blob转file
 * @param {*} blob 文件流
 * @param {*} filename 文件名
 */
export function blobToFile(blob, filename) {
  return new File([blob], filename)
}

// 格式化文件流下载content-disposition的filename值
// headers为请求返回的header
export function formatFileName(headers) {
  const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
  const contentDisposition = decodeURIComponent(
    headers['content-disposition'] || headers['Content-Disposition']
  )
  const result = patt.exec(contentDisposition)
  let fileName = result[1]
  fileName = fileName.replace(/\"/g, '')
  return fileName
}

// blob文件下载
// blob为返回文件流；filename为文件名信息
export function downloadFile(blob, fileName) {
  if (window.navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName)
  } else {
    // var link = document.createElement('a')
    // link.href = window.URL.createObjectURL(blob)
    var link = document.createElement('a')
    try {
      var objectUrl = URL.createObjectURL(blob)
      link.href = objectUrl
    } catch {
      link.href = blob
    }
  }
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(objectUrl)
  document.body.removeChild(link)
}
