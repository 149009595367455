import { createRouter, createWebHistory, createWebHashHistory, useRoute } from 'vue-router'
import { useSysStore } from '@/store/modules/sys'

// 路由信息
const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/medical/middlePage'
  },
  {
    path: '/errorPage',
    name: 'errorPage',
    component: () => import('@/views/errorPage/index.vue'),
    meta: {
      title: '错误页'
    }
  },
  {
    path: '/medical',
    name: 'layout',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: 'middlePage',
        name: 'middlePage',
        component: () => import('@/views/middlePage/middlePage.vue'),
        meta: {
          title: '',
          module: ''
        }
      },
      {
        path: 'mobileHome',
        name: 'mobileHome',
        component: () => import('@/views/tabbarMobile/mobileHome.vue'),
        meta: {
          title: '首页',
          module: '',
          keepAlive: document.body.clientWidth < 600
        }
      },
      {
        path: 'mobileHandle',
        name: 'mobileHandle',
        component: () => import('@/views/tabbarMobile/mobileHandle.vue'),
        meta: {
          title: '办理',
          module: ''
        }
      },
      {
        path: 'mobileConsulting',
        name: 'mobileConsulting',
        component: () => import('@/views/tabbarMobile/mobileConsulting.vue'),
        meta: {
          title: '咨询',
          module: '',
          keepAlive: document.body.clientWidth < 600
        }
      },
      {
        path: 'mobileGuide',
        name: 'mobileGuide',
        component: () => import('@/views/tabbarMobile/mobileGuide.vue'),
        meta: {
          title: '帮助',
          module: ''
        }
      },
      {
        path: 'view',
        name: 'view',
        component: () => import('@/views/layout/index.vue'),
        children: [
          {
            path: 'home',
            name: 'home',
            component: () => import('@/views/pc/home/index.vue'),
            meta: {
              title: '首页',
              module: '首页'
            }
          },
          {
            path: 'hotpots',
            name: 'hotpots',
            component: () => import('@/views/service/hotpots/index.vue'),
            meta: {
              title: '最新热点主页面',
              module: '最新热点',
              keepAlive: document.body.clientWidth < 600
            }
          },
          {
            path: 'hotpotsDetail',
            name: 'hotpotsDetail',
            component: () => import('@/views/service/hotpots/detail.vue'),
            meta: {
              title: '最新热点详情',
              module: '最新热点'
            }
          },
          {
            path: 'normal',
            name: 'normal',
            component: () => import('@/views/service/normal/index.vue'),
            meta: {
              title: '常见问题主页',
              module: '常见问题',
              keepAlive: document.body.clientWidth < 600
            }
          },
          {
            path: 'normalDetail',
            name: 'normalDetail',
            component: () => import('@/views/service/normal/detail.vue'),
            meta: {
              title: '常见问题详情',
              module: '常见问题'
            }
          },
          {
            path: 'total',
            name: 'total',
            component: () => import('@/views/service/total/index.vue'),
            meta: {
              title: '智能检索主页',
              module: '智能检索',
              keepAlive: document.body.clientWidth < 600
            }
          },
          {
            path: 'totalDetail',
            name: 'totalDetail',
            component: () => import('@/views/service/total/detail.vue'),
            meta: {
              title: '智能检索详情',
              module: '智能检索'
            }
          },
          {
            path: 'guide',
            name: 'guide',
            component: () => import('@/views/service/guide/index.vue'),
            meta: {
              title: '办事指南主页',
              module: '办事指南',
              keepAlive: document.body.clientWidth < 600
            }
          },
          {
            path: 'guideDetail',
            name: 'guideDetail',
            component: () => import('@/views/service/guide/detail.vue'),
            meta: {
              title: '办事指南详情',
              module: '办事指南'
            }
          },
          {
            path: 'policy',
            name: 'policy',
            component: () => import('@/views/service/policy/index.vue'),
            meta: {
              title: '政策文件库主页',
              module: '政策文件',
              keepAlive: document.body.clientWidth < 600
            }
          },
          {
            path: 'policyDetail',
            name: 'policyDetail',
            component: () => import('@/views/service/policy/detail.vue'),
            meta: {
              title: '政策文件库详情',
              module: '政策文件'
            }
          },
          {
            path: 'contact',
            name: 'contact',
            component: () => import('@/views/service/contact/index.vue'),
            meta: {
              title: '常用电话',
              module: '常用电话'
            }
          },
          {
            path: 'common',
            name: 'common',
            component: () => import('@/views/service/common/index.vue'),
            meta: {
              title: '常用下载',
              module: '常用下载'
            }
          },
          {
            path: 'networkOffice',
            name: 'networkOffice',
            component: () => import('@/views/service/networkOffice/index.vue'),
            meta: {
              title: '网办手册',
              module: '网办手册'
            }
          },
          {
            path: 'costumer',
            name: 'costumer',
            component: () => import('@/views/costumer/index.vue'),
            meta: {
              title: '客服指引',
              module: '客服指引'
            }
          },
          {
            path: 'noticeDetail',
            name: 'noticeDetail',
            component: () => import('@/views/notice/noticeDetail.vue'),
            meta: {
              title: '公告详情',
              module: '公告详情'
            }
          },
          {
            path: 'addressMap',
            name: 'addressMap',
            component: () => import('@/views/addressMap/index.vue'),
            meta: {
              title: '社保地图',
              module: '社保地图',
              keepAlive: document.body.clientWidth > 600
            }
          },
          {
            path: 'answers',
            name: 'answers',
            component: () => import('@/views/answers/index.vue'),
            meta: {
              title: '问答',
              module: '问答'
            }
          },
          {
            path: 'consulting',
            name: 'consulting',
            component: () => import('@/views/consulting/index.vue'),
            meta: {
              title: '业务咨询',
              module: '业务咨询'
            }
          },
          {
            path: 'businessHandling',
            name: 'businessHandling',
            component: () => import('@/views/businessHandling/index.vue'),
            meta: {
              title: '业务办理',
              module: '业务办理'
            }
          },
          {
            path: 'WorkOrderDetail',
            name: 'WorkOrderDetail',
            component: () => import('@/views/businessHandling/detail.vue'),
            meta: {
              title: '工单详情',
              module: '工单详情'
            }
          },
          {
            path: 'WorkOrderAdd',
            name: 'WorkOrderAdd',
            component: () => import('@/views/businessHandling/increased.vue'),
            meta: {
              title: '工单新增',
              module: '工单新增'
            }
          },
          {
            path: 'WorkOrderTip',
            name: 'WorkOrderTip',
            component: () => import('@/views/businessHandling/successTip.vue'),
            meta: {
              title: '成功提示',
              module: '成功提示'
            }
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory('/taianwxperson/'), // history
  // history: createWebHashHistory(), // hash
  routes
})

// 增加全局守卫，当非企微环境下时（路由中无userid），跳转去错误提示页
router.beforeEach((to, from, next) => {
  const sysStore = useSysStore()
  const route = useRoute()
  if (sysStore.userId || to.query.userId) {
    next()
  } else if (to.name === 'errorPage') {
    next()
  } else {
    next({
      name: 'errorPage'
    })
  }
  next()
})

// // 360极速模式下，双击刷新，页面资源丢失
// router.onError(() => {
//   const targetPath = router.history.pending.fullPath;
//   router.replace(targetPath);
// })

export default router
