import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import { message } from 'ant-design-vue'
import Loading from '@/utils/loading'
import qs from 'qs'
import router from '../../router'
import { IBaseResponse } from '@/api/modules/common'
import { formatFileName, downloadFile } from '@/utils/file-reader.js'
import { isPc } from '@/utils'

const service: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 1000 * 30,
  withCredentials: true
})

service.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    // 不传递默认开启loading
    if (!config.hideLoading) {
      Loading.service({
        text: '加载中...',
        background: 'rgba(255, 255, 255, 0.4)'
      })
    }
    // 增加默认content-type
    config.headers = config.headers || { 'Content-Type': ContentTypeEnum.JSON }
    
    const sessionData = sessionStorage.getItem('pinia-sys') ? JSON.parse(sessionStorage.getItem('pinia-sys') as string) : {agentId:'',userId:'',corpId:''}
    config.headers['agentId'] = sessionData.agentId
    config.headers['userId'] = sessionData.userId
    config.headers['corpId'] = sessionData.corpId
    console.warn(`==> request ${config.url}`, config)
    const contentType = config.headers['content-type'] || config.headers['Content-Type']
    const data = config.params
    if (config.method?.toLocaleUpperCase() == 'POST' && data) {
      if (ContentTypeEnum.FORM_DATA == contentType) {
        const fd = new FormData()
        Object.keys(data).forEach((key) => fd.append(key, data[key]))
        config.data = fd
      } else if (ContentTypeEnum.FORM_URLENCODED == contentType) {
        config.data = qs.stringify(data)
      } else if (ContentTypeEnum.JSON == contentType || ContentTypeEnum.TEXT == contentType) {
        config.data = data
      }
      delete config.params
    }
    return config
  },
  (error: any) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (res: any) => {
    Loading.close()
    if (res.config.responseType === 'blob') {
      // 下载文件流
        const fileName = formatFileName(res.headers)
        downloadFile(res.data, fileName)
        return res
    } else {
      // 正常返回
      if (res.status) {
        const { code, data } = res.data
        console.log('response=>', res.data)
        if (code !== 100) {
          // 登录超时,重新登录
          if (code === 401) {
            // store.dispatch('FedLogOut').then(() => {
            //   location.reload()
            // })
            router.replace('/error')
          } else {
              if (isPc()) {
                 message.error(res.data.message || '服务器访问出错了~')
              } else {
                Toast(res.data.message || '服务器访问出错了~')
              }
          }
          return
        } else {
          return [null, data]
        }
      }
    }
  },
  (error: Error) => {
    if (error.message?.includes('timeout')) {
      // Toast('请求超时!')
    }
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
// TODO: 优化这里的类型
const http = <T = any>(config: CustomAxiosRequestConfig): Promise<any> => {
  return new Promise((resolve, reject) => {
    service
      .request<IBaseResponse<T>>(config)
      .then((res: any) => resolve(res))
      .catch((err: any) => reject(err))
  })
}

export default http

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  hideLoading?: boolean // 隐藏loading
  needLogin?: boolean // 需要登录
}

/**
 * @description: 请求方法
 */
export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

/**
 * @description: 常用的contentTyp类型
 */
export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // json
  TEXT = 'text/plain;charset=UTF-8',
  // form-data 一般配合qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  上传
  FORM_DATA = 'multipart/form-data;charset=UTF-8'
}
