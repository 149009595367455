class Loading {
  service(info: any) {
    if (document.querySelector('#vue-loading')) return
    const divE = document.createElement('div')
    divE.id = 'vue-loading'
    divE.innerHTML = `<div class="ignore-loading-box">
      <svg class="ignore-loading-circular" viewBox="25 25 50 50">
      <circle class="path ignore-path" cx="50" cy="50" r="20" fill="none"/>
      <div class="ignore-loading-text">${info.text}</div>
    </svg></div>`
    document.body.appendChild(divE)
    // 阻止遮罩滑动
    document.querySelector('#vue-loading')?.addEventListener('touchmove', function (e) {
      e.stopPropagation()
      e.preventDefault()
    })
    return this
  }
  close() {
    const tpl = document.querySelector('#vue-loading')
    if (tpl) {
      document.body.removeChild(tpl)
    }
  }
}

export default new Loading()
