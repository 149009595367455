import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'

import vantPlugin from './utils/components/vant'
import antdPlugin from './utils/components/antd'
import remConfig from './utils/remConfig'
import './utils/components/vant-other'
import './utils/components/antd-other'
import globalComponents from './utils/components/global'
import globalDirective from './utils/directive/global'
import '@vant/touch-emulator'
// remConfig()

const app = createApp(App)

// vantPlugin(app)
antdPlugin(app)
globalComponents(app)
globalDirective(app)

app.use(router).use(store).mount('#app')
