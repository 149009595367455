import http, { ContentTypeEnum } from '@/utils/http/index'
import {
    businessRes,
    businessParam,
    businessAreaRes,
    businessAreaParam
} from './modules/medical'
/* *****系统配置***** */

enum api {
  getBusinessGroupList = '/businessGroup/getBusinessGroupListWithHasData',
  getBusinessAreaList = '/businessArea/getBusinessAreaList',
  getHotspotList = '/qaInfo/getHotspotList',
  getCommonList = '/qaInfo/getCommonList',
  getAllQaList = '/qaInfo/getAllQaList',
  getQaInfo = '/qaInfo/getQaInfo',
  getGuideInfoList = '/guide/getGuideInfoList',
  getGuideInfoDetail = '/guide/getGuideInfoDetail',
  getPolicyInfoList = '/policy/getPolicyInfoList',
  getPolicyInfoDetail = '/policy/getPolicyInfoDetail',
  getSignature = '/storage/getSignature',
  getStorageFile = '/storage/file',
  getCommonInfoList = '/commonInfo/getCommonInfoList',
  getBusinessNoticeList = '/businessNotice/getBusinessNoticeInfo',
  getBusinessFilePage = '/businessFile/getBusinessFilePage',
  configSignature = '/auth/configSignature',
  configAppSignature = '/auth/configAppSignature',
  getHomeQaList = '/qaInfo/getHomeQaList',
  getNoticeList = '/businessNotice/getNoticeList',
  getNoticeInfo = '/businessNotice/getNoticeInfo',
  getConsultInfo = '/consult/getConsultInfo',
  getKfList = '/consult/getKfList',
  getMyCreateWorkOrderList = '/workorder/getMyCreateWorkOrderList',
  getWorkOrderInfo = '/workorder/getWorkOrderInfo',
  updateWorkOrderInfo = '/workorder/updateWorkOrderInfo',
  voidWorkOrderInfoById = '/workorder/voidWorkOrderInfoById',
  upload = '/storage/upload',
  getWorkOrderTypeList = '/workType/getWorkOrderTypeList',
  getWorkOrderTypeListOfDropDown = '/workType/getWorkOrderTypeListOfDropDown',
  getWorkOrderTypeInfo = '/workType/getWorkOrderTypeInfo',
  createWorkOrder = '/workorder/createWorkOrder',
}

// 获取业务分组
export const GetBusinessGroupList = (params?: businessParam) => {
  return http<businessRes>({
    url: api.getBusinessGroupList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取业务地域code列表
export const GetBusinessAreaList = (params?: businessAreaParam) => {
  return http<businessAreaRes>({
    url: api.getBusinessAreaList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取热点问题列表
export const GetHotspotList = (params?: any) => {
  return http<any>({
    url: api.getHotspotList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取常见问题列表
export const GetCommonList = (params?: any) => {
  return http<any>({
    url: api.getCommonList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取全部问题列表
export const GetAllQaList = (params?: any) => {
  return http<any>({
    url: api.getAllQaList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取问答详情
export const GetQaInfo = (params?: any) => {
  return http<any>({
    url: api.getQaInfo,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取办事指南列表
export const GetGuideInfoList = (params?: any) => {
  return http<any>({
    url: api.getGuideInfoList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取办事指南详情
export const GetGuideInfoDetail = (params?: any) => {
  return http<any>({
    url: api.getGuideInfoDetail,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取政策文件列表
export const GetPolicyInfoList = (params?: any) => {
  return http<any>({
    url: api.getPolicyInfoList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取政策文件详情
export const GetPolicyInfoDetail = (params?: any) => {
  return http<any>({
    url: api.getPolicyInfoDetail,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取签名
export const GetSignature = (params?: any) => {
  return http<any>({
    url: api.getSignature,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 下载文件
export const GetStorageFile = (params?: any) => {
  return http<any>({
    url: api.getStorageFile,
    method: 'GET',
    params: params,
    responseType: 'blob',
    headers: {
      isDownload: 'true'
    }
  })
}

// 获取公共信息列表
export const GetCommonInfoList = (params?: any) => {
  return http<any>({
    url: api.getCommonInfoList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取通知公告列表
export const GetBusinessNoticeList = (params?: any) => {
  return http<any>({
    url: api.getBusinessNoticeList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取常用下载列表
export const GetBusinessFilePage = (params?: any) => {
  return http<any>({
    url: api.getBusinessFilePage,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// config接口权限验证配置签名
export const GetJsapiTicket = (params?: any) => {
  return http<any>({
    url: api.configSignature,
    method: 'POST',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// agentConfig接口权限验证配置签名
export const GetQYJsapiTicket = (params?: any) => {
  return http<any>({
    url: api.configAppSignature,
    method: 'POST',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取首页问题滑块列表
export const GetHomeQaList = (params?: any) => {
  return http<any>({
    url: api.getHomeQaList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取通知公告列表
export const GetNoticeList = (params?: any) => {
  return http<any>({
    url: api.getNoticeList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取通知公告列表
export const GetNoticeInfo = (params?: any) => {
  return http<any>({
    url: api.getNoticeInfo,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取咨询情报
export const GetConsultInfo = (params?: any) => {
  return http<any>({
    url: api.getConsultInfo,
    method: 'GET',
    params: params,
    hideLoading: true,
    headers: {
      'Content-Type': ContentTypeEnum.JSON,
    }
  })
}

// 获取指定区域的客服列表
export const GetKfList = (params?: any) => {
  return http<any>({
    url: api.getKfList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取工单列表
export const GetMyCreateWorkOrderList = (params?: any) => {
  return http<any>({
    url: api.getMyCreateWorkOrderList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取工单类型列表
export const GetWorkOrderTypeList = (params?: any) => {
  return http<any>({
    url: api.getWorkOrderTypeList,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取工单类型列表（前端）
export const GetWorkOrderTypeListOfDropDown = (params?: any) => {
  return http<any>({
    url: api.getWorkOrderTypeListOfDropDown,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取工单类型详情
export const GetWorkOrderTypeInfo = (params?: any) => {
  return http<any>({
    url: api.getWorkOrderTypeInfo,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 获取工单详情
export const GetWorkOrderInfo = (params?: any) => {
  return http<any>({
    url: api.getWorkOrderInfo,
    method: 'GET',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 添加工单
export const CreateWorkOrder = (params?: any) => {
  return http<any>({
    url: api.createWorkOrder,
    method: 'POST',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 更新工单
export const UpdateWorkOrderInfo = (params?: any) => {
  return http<any>({
    url: api.updateWorkOrderInfo,
    method: 'POST',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 作废工单
export const VoidWorkOrderInfoById = (params?: any) => {
  return http<any>({
    url: api.voidWorkOrderInfoById,
    method: 'POST',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}

// 文件上传
export const Upload = (params?: any) => {
  return http<any>({
    url: api.upload,
    method: 'POST',
    params: params,
    headers: {
      'Content-Type': ContentTypeEnum.JSON
    }
  })
}
