import lodash from 'lodash-es'
import { Toast } from 'vant'
import Clipboard from 'clipboard'
import { App } from 'vue'

export default function(app: App){
  // 修改当前页标题
  app.directive('title', {
    mounted(el, binding){
      document.title = binding.value
    },
    updated(el, binding){
      document.title = binding.value
    }
  })
  // 防抖
  app.directive('debounce', {
    mounted(el, binding) {
      const wait = Number(binding.arg) | 1000
      const func = lodash.debounce(binding.value, wait, {
        'leading': true,
        'trailing': false
      })
      // leading 先调用后等待 还是 trailing 先等待后调用
      el.addEventListener('click', func)
    },
  })
  // 复制
  app.directive('clipboard', {
    mounted(el, binding){
      const clipboard = new Clipboard(el, { text: () => binding.value })
      clipboard.on('success', e => {
        Toast('复制成功！')
      })
      clipboard.on('error', e => {
        Toast('复制失败！')
      })
    },
    updated(el, binding){
      const clipboard = new Clipboard(el, { text: () => binding.value })
      clipboard.on('success', e => {
        Toast('复制成功！')
      })
      clipboard.on('error', e => {
        Toast('复制失败！')
      })
    }
  })
}
