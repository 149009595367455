import { defineStore } from 'pinia'

export const useSysStore = defineStore('sys', {
  state: () => {
    return {
      isPc: false,
      areaCode: '',
      areaName: '',
      agentId: '',
      userId: '',
      corpId: '',
      tabsType: '0'
    }
  },
  actions: {
    setAreaCode(areaCode) {
      this.areaCode = areaCode
    },
    setAreaName(areaName) {
      this.areaName = areaName
    },
    setAgentId(agentId) {
      this.agentId = agentId
    },
    setUserId(userId) {
      this.userId = userId
    },
    setCorpId(corpId) {
      this.corpId = corpId
    },
    setTabsType(tabsType) {
      this.tabsType = tabsType
    }
  }
})
